export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  // Ok so here I need to see if there's a studentId to extract and I need to store it then
  useTeacherPesudoStudentLogin().initialize();

  const { initializeAppUserData, loadUserPermissions } = useAppUserData();
  await initializeAppUserData();

  await loadUserPermissions();

  const { initializeStudentsClassroomData } = useStudentClassroomsDataStore();
  await initializeStudentsClassroomData();
});
